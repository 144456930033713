import styled from "styled-components";

import Body from "../Components/Body";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import { Title, Subtitle, Paragraph } from "../Components/Text";
import { Link } from "react-router-dom";

const StyledRouterLink = styled.span`
  color: black;
  cursor: pointer;
  transition: color 0.175s;
  &:hover {
    color: #b30f60;
  }
`;

function Careers() {
  const content = (
    <>
    <Paragraph>
        <Title>
          <Link to="/scientist" style={{ textDecoration: "none" }}>
            <StyledRouterLink>Research Scientist</StyledRouterLink>
          </Link>
        </Title>
        <Subtitle>San Francisco, CA / Full Time</Subtitle>
      </Paragraph>
      <Paragraph>
        <Title>
          <Link to="/researchengineer" style={{ textDecoration: "none" }}>
            <StyledRouterLink>Research Engineer</StyledRouterLink>
          </Link>
        </Title>
        <Subtitle>San Francisco, CA / Full Time</Subtitle>
      </Paragraph>
      {/* <Paragraph>
        <Title>
          <Link to="/intern" style={{ textDecoration: "none" }}>
            <StyledRouterLink>Intern (Member of Technical Staff)</StyledRouterLink>
          </Link>
        </Title>
        <Subtitle>San Francisco, CA / Full Time</Subtitle>
      </Paragraph> */}
    </>
  );

  return (
    <Body>
      <Header />
      {content}
      <Footer />
    </Body>
  );
}

export default Careers;
