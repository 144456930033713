
export const styles = {
    background: '#13111C',
    text: '#FEF4FE',
    FS1: 44,
    FS2: 32,
    FS3: 21,
    FS4: 21,
    FS5: 16,
    S1: 84,
    S2: 48,
    S3: 24,
    S4: 12,
};