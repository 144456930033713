import styled from "styled-components";

import Logo from "./Logo";
import { Link, H4 } from "./Text";
import { RouterLink } from "./Header";

import { styles } from '../variables'

const StyledFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: start;
  border-top: 1px solid #aaa;
  padding-top: ${styles.S2}px;
  padding-bottom: ${styles.S1}px;
  @media (max-width: 600px) {
    flex-direction: column;
    align-items: start;
    padding-top: 30px;
  }
`;

const Spacer = styled.div`
  height: ${(props) => props.$height}px;
  width: 100%;
  display: block;
`
export default function Footer() {
  return (
    <StyledFooter>
      <Logo />
      <br />
      <H4>
        <Link secondary href="https://maps.app.goo.gl/ie2NQGRPcodUTyDj7">
          Marathon Fusion, Inc.
          <br />
          747 Clementina Street
          <br />
          San Francisco, CA 94103
        </Link>
        <br />

        <br />
        <Link secondary href="mailto:info@marathonfusion.com">
          info@marathonfusion.com
        </Link>
      </H4>
      <br />
      <H4>
        <Link secondary href="https://www.linkedin.com/company/marathon-fusion">
          LinkedIn
        </Link>
      </H4>
    </StyledFooter>
  );
}

{/* <RouterLink to="/">
<span
  onClick={() => {
    window.scrollTo(0, 0);
  }}
>
  Company
</span>
</RouterLink>
<br />
<RouterLink to="/technology">
<span
  onClick={() => {
    window.scrollTo(0, 0);
  }}
>
  Technology
</span>
</RouterLink>

<br /> */}