import styled from "styled-components";

const StyledBody = styled.div`
  max-width: 1000px;
  @media screen and (max-width: 1000px) {
    max-width: 500px;
  }
  margin: auto;
  padding-left: 30px;
  padding-right: 30px;
`;

export default function Body({ children }) {
  return <StyledBody>{children}</StyledBody>;
}
