import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./Pages/App";
import Technology from "./Pages/Technology";
import Careers from "./Pages/Careers";
import ResearchEngineer from "./Pages/ResearchEngineer";
import Intern from "./Pages/Intern";
import Scientist from "./Pages/Scientist";

import reportWebVitals from "./reportWebVitals";
import { sendToVercelAnalytics } from "./vitals";
import { Route, Navigate, createBrowserRouter, RouterProvider } from "react-router-dom";

const Redirect = ({value}) => 
  {window.location.href = value}

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/technology",
    element: <Redirect value="https://www.marathonfusion.com/"/>
  },
  {
    path: "/careers",
    element: <Redirect value="https://jobs.ashbyhq.com/Marathon%20Fusion"/>
  },
  {
    path: "/researchengineer",
    element: <Redirect value="https://jobs.ashbyhq.com/Marathon%20Fusion"/>
  },
  {
    path: "/intern",
    element: <Redirect value="https://jobs.ashbyhq.com/Marathon%20Fusion"/>
  },
  {
    path: "/scientist",
    element: <Redirect value="https://jobs.ashbyhq.com/Marathon%20Fusion"/>
  },
]);

ReactDOM.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals(sendToVercelAnalytics);
