import styled from "styled-components";
import { Link } from "react-router-dom";

import { styles } from '../variables'

const RouterLink = ({ to, children }) => {
  return (
    <Link to={to} style={{ textDecoration: "none", color: "black" }}>
      {children}
    </Link>
  );
};

const Logo = styled.div`
  font-weight: 800;
  font-size: 24px;
  line-height: 1em;
  text-decoration: none;
  color: ${styles.text};
`;
  
export default function Header() {
  return (
    <RouterLink to="/" style={{ textDecoration: "none" }}>
      <Logo>
        <div>Marathon</div>
        <div>Fusion</div>
      </Logo>
    </RouterLink>
  );
}
