import styled from "styled-components";
import { Link } from "react-router-dom";

import Logo from "./Logo";
import { styles } from '../variables'

const StyledRouterLink = styled.span`
  transition: color 0.225s;
  color: ${styles.text};
  &:hover {
    color: #c8e0ff;
  }
  font-weight: 500;
  text-decoration: ${(props) => (props.secondary ? "none" : "")};
`;

export const RouterLink = ({ to, children }) => {
  return (
    <Link to={to} style={{ textDecoration: "none" }}>
      <StyledRouterLink>{children}</StyledRouterLink>
    </Link>
  );
};

const StyledHeader = styled.div`
  padding-top: 38px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 600px) {
    flex-direction: column;
    align-items: start;
  }
`;

const Menu = styled.div`
  display: flex;
  @media (max-width: 600px) {
    flex-direction: column;
    align-items: start;
  }
`;

const MenuItem = styled.div`
  margin-left: 32px;
  border-radius: 1px;
  padding: 2px 4px;
  font-size: 18px;
  @media (max-width: 600px) {
    margin-left: 0;
    padding: 0;
    margin-top: 8px;
  }
`;

export default function Header() {
  return (
    <StyledHeader>
      <Logo />
      {/* <Menu>
        <MenuItem>
          <RouterLink to="/" style={{ textDecoration: "none" }}>
            company
          </RouterLink>
        </MenuItem>
        <MenuItem>
          <RouterLink to="https://jobs.ashbyhq.com/Marathon%20Fusion" style={{ textDecoration: "none" }}>
            careers
          </RouterLink>
        </MenuItem>
      </Menu> */}
    </StyledHeader>
  );
}
