import styled from "styled-components";

import { styles } from '../variables'

// height: ${(props) => props.$top}px;
const StyledTitle = styled.div`
  font-size: ${(props) => props.level == 1 ? styles.FS1 : styles.FS2}px;
  font-weight: 900;
`;
// padding-bottom: 0.8em;

const StyledSubtitle = styled.div`
  font-size: ${styles.FS3}px;
  font-weight: 400;
`;

const StyledParagraph = styled.p`
  margin-block-start: 0.4em;
  font-size: ${styles.FS5}px;
`;

const StyledLink = styled.a`
transition: color 0.225s;
  color: ${styles.text};
  &:hover {
    color: #c8e0ff;
  }
  text-decoration: ${(props) => (props.secondary ? "none" : "")};
`;

const StyledH4 = styled.div`
  font-size: 0.8em;
  line-height: 1.3em;
`;

const StyledH5 = styled.div`
  font-size: ${styles.FS4}px;
  font-weight: 800;
  color: ${(props) => (props.mode == "dark" ? styles.background : styles.text)};
`;


export function Title({ children, level }) {
  return <StyledTitle level={level || 1}>{children}</StyledTitle>;
}

export function Subtitle({ children }) {
  return <StyledSubtitle>{children}</StyledSubtitle>;
}

export function Paragraph({ children }) {
  return <StyledParagraph>{children}</StyledParagraph>;
}

export function H4({ children }) {
  return <StyledH4>{children}</StyledH4>;
}

export function H5({ children, mode }) {
  return <StyledH5 mode={mode}>{children}</StyledH5>;
}

export function Link({ href, secondary, children }) {
  return (
    <StyledLink secondary={secondary} href={href}>
      {children}
    </StyledLink>
  );
}
