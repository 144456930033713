import React, { useLayoutEffect, useState } from 'react';

import styled from "styled-components";

import Body from "../Components/Body";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import { Title, Subtitle, H5, Paragraph, Link } from "../Components/Text";

import mines from "../images/logos/mines.jpg";
import fund from "../images/logos/fund.png";
import bef from "../images/logos/bef.jpg";
import cc from "../images/logos/cc.png";
import c2c from "../images/logos/c2c.png";
import CFS from "../images/logos/CFS.jpg";
import collab from "../images/logos/collab.png";
import helion from "../images/logos/helion.png";
import inl from "../images/logos/inl2.jpg";
import lbnl from "../images/logos/lbnl.jpg";
import arpae from "../images/logos/arpae.png";
import aegis from "../images/aegis.png";
import atomics from "../images/atomics.png";
import anglo from "../images/anglo.png";
import landscape from "../images/landscape.jpg";
import plasma from "../images/plasma.png";

import { styles } from '../variables'

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

function ShowWindowDimensions(props) {
  const [width, height] = useWindowSize();
  return <span>Window size: {width} x {height}</span>;
}

const ImageBox = styled.div`
  width: 100%;
  max-height: 300px;
  overflow: hidden;
  position: relative;
  border-radius: 8px;
`
const imageHeight = 350;

const ImageBoxText = styled.div`
  font-size: ${styles.FS2}px;
  font-weight: 900;
  padding: 0px 200px;
  @media screen and (max-width: 1000px) {
    padding: 0px 50px;
    font-size: ${styles.FS4}px;
  }
  @media screen and (max-width: 500px) {
    padding: 0px 20px;
    font-size: ${styles.FS5}px;
  }
`

const ImageBoxTextBox = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`

const BigImage = styled.img`
  width: 100%;
  max-height: ${imageHeight}px;
  height: auto;
  background-size: contain;
`

const Background = styled.div`
  background: ${styles.background};
  color: ${styles.text};
`

const Button = styled.div`
  transition: border-color 0.225s, background 0.225s, color 0.225s;
  cursor: pointer;
  border: 2px solid ${styles.background};
  background: ${styles.text};
  color: ${styles.background};
  padding: 12px 40px;
  display: inline-block;
  border-radius: 8px;
  font-weight: 800;
  font-size: 18px;
  &:hover {
    background: ${styles.background};
    color: ${styles.text};
    border: 2px solid ${styles.text};
  }
`

const LogoImage = styled.img`
  height: ${(props) => props.$top}px;
  width: auto;
  flex-shrink: 1;
  background-size: contain;
`;

const Image = styled.img`
  height: ${(props) => props.$height}px;
  width: auto;
  @media screen and (max-width: 1000px) {
    height: auto;
    width: 100%;
  }
  flex-shrink: 1;
  margin-right: 20px;
  margin-top: 5px;
  background-size: contain;
  border-radius: 8px
`;

const MaxWidth = styled.div`
  max-width: ${(props) => props.$width}px;;
`

const Spacer = styled.div`
  height: ${(props) => props.$height}px;
  width: 100%;
  display: block;
`

const FullImage = styled.img`
  width: 100%;
  border-radius: 8px;
`;

const Break = styled.div`
  flex-basis: 100%;
  height: 0;
`;

const ImageFlexbox = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-basis: 1;
  align-items: center;
  @media screen and (max-width: 500px) {
    justify-content: center;
  }
`;

const Split = styled.div`
  display: flex;
  gap: 36px;
  justify-content: space-evenly;
  @media screen and (max-width: 1000px) {
    flex-direction: column;
    gap: 43px;
  }
`;

const SplitSection = styled.div`
  flex-basis: 0;
  flex-grow: 3;
`;

const SplitSectionLogos = styled.div`
  flex-basis: 0;
  flex-grow: 1;
  @media screen and (max-width: 1000px) {
    display: flex;
    gap: 40px;
    justify-content: space-between;
  }
  @media screen and (max-width: 500px) {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
  }
`;

const SplitSection2 = styled.div`
  margin-left: 100px;
  @media screen and (max-width: 1000px) {
    margin-left: 0px;
  }
  display: flex;
  align-items: center;
`;

const BackgroundBlock = styled.div`
  width: 100%;
  background: white;
`

const FixedWidth = styled.div `
  min-width: 340px;
  @media screen and (max-width: 1000px) {
    min-width: 0px;
    max-width: 320px;
  }
`

const Buffer = styled.div`
  margin-left: ${(props) => props.left}px;;
`

function App() {
  useWindowSize();
  
  const width = window.innerWidth;

  const content = (
    <>
      <Body>
        <Spacer $height={80} />
        <Split>
          <SplitSection>
            <FixedWidth>
              <Title>A fuel cycle to catalyze the fusion age</Title>
              <Spacer $height={styles.S2} />
              <Subtitle>
                We're building fuel processing technology to make fusion power plants robust, practical and widespread.
              </Subtitle>
              <Spacer $height={styles.S2} />
              <Link href="https://jobs.ashbyhq.com/Marathon%20Fusion">
                <Button>
                  Join Us
                </Button>
              </Link>
            </FixedWidth>
          </SplitSection>
          <SplitSection2>
            <Image $height={200} src={aegis} />
          </SplitSection2>
        </Split>
        <Spacer $height={styles.S1} />
        <MaxWidth $width={500}>
          <Title level={2}>Securing the path to a competitive fusion industry</Title>
        </MaxWidth>
        <Spacer $height={styles.S3} />
        <Split>
          <SplitSection>
            <H5>
              Fast
            </H5>
            <Spacer $height={styles.S4} />
            <Paragraph>
              The superpermeable hydrogen pump greatly accelerates tritium processing using plasma technology and separation membranes.
            </Paragraph>
            <Paragraph>
              Speed enables the efficient use of scarce isotopes critical to fusion’s viability.
            </Paragraph>
          </SplitSection>
          <SplitSection>
            <H5>
              Focused
            </H5>
            <Spacer $height={styles.S4} />
            <Paragraph>
              Existing systems are complex and were never designed for commercial operation.
            </Paragraph>
            <Paragraph>
              We’re streamlining processing for a consolidated fuel cycle that’s robust, easier to manage, and primed for scaling.
            </Paragraph>
          </SplitSection>
          <SplitSection>
            <H5>
              Feasible
            </H5>
            <Spacer $height={styles.S4} />
            <Paragraph>
              Our approach provides efficient, economically viable systems enabled by engineering breakthroughs to drive real-world impact.
            </Paragraph>
            <Paragraph>
              The result is improved performance at reduced cost, paving the way for fusion to address global energy demands.
            </Paragraph>
          </SplitSection>
        </Split>

        <Spacer $height={styles.S1} />
        <MaxWidth $width={600}>
          <Title level={2} >Embedded in the fusion ecosystem</Title>
        </MaxWidth>
        <Spacer $height={styles.S3} />
      </Body>


      <BackgroundBlock>
        <Body>
          <Spacer $height={styles.S2} />
          <Split>

            <SplitSectionLogos>
              <H5 mode="dark">
                Investors
              </H5>
              {width >= 1000 ? (
                <>
                  <Spacer $height={styles.S4} />
                  <Spacer $height={5} />
                </>
              ) : null}
              <ImageFlexbox>
                <Link href="https://www.1517fund.com/">
                  <LogoImage height={40} src={fund} />
                </Link>
                <Buffer left={30}>
                  <Link href="https://sharedfuture.xyz/">
                    <LogoImage height={42} src={collab} />
                  </Link>
                </Buffer>
                <Spacer $height={10} />
                <Link href="https://strong-atomics.com/">
                  <LogoImage height={26} src={atomics} />
                </Link>
                <Spacer $height={3} />
                <Link href="https://www.angloamerican.com/">
                  <LogoImage height={55} src={anglo} />
                </Link>
              </ImageFlexbox>
            </SplitSectionLogos>

            <SplitSectionLogos>
              <H5 mode="dark">
                Industry
              </H5>
              {width >= 1000 ? (
                <>
                  <Spacer $height={styles.S4} />
                </>
              ) : null}
              <ImageFlexbox>
                <Link href="https://cfs.energy/">
                  <LogoImage height={70} src={CFS} />
                </Link>
                {width >= 1000 ? (<Spacer $height={14} />) : (<Spacer $height={8} />)}
                <Link href="https://www.helionenergy.com/">
                  <LogoImage height={60} src={helion} />
                </Link>
              </ImageFlexbox>
            </SplitSectionLogos>

            <SplitSectionLogos>
              <H5 mode="dark">
                Partners
              </H5>
              {width >= 1000 ? (
                <>
                  <Spacer $height={styles.S4} />
                  <Spacer $height={9} />
                </>
              ) : null}
              <ImageFlexbox>
                  <Link href="https://arpa-e.energy.gov/news-and-media/press-releases/us-department-energy-announces-9-million-transformative-energy">
                    {width >= 1000 ? (<LogoImage height={40} src={arpae} />) : (<LogoImage height={38} src={arpae} />)}
                  </Link>

                <Buffer left={30}>
                  <Link href="https://infuse.ornl.gov/awards/testing-of-engineered-membranes-in-fusion-relevant-metal-foil-pumps/">
                    {width >= 1000 ? (<LogoImage height={60} src={mines} />) : (<LogoImage height={44} src={mines} />)}
                  </Link>
                </Buffer>
                
                {width >= 1000 ? (<Spacer $height={20} />) : (<Spacer $height={12} />)}

                <Link href="https://inl.gov/">
                  <Spacer $height={5} />
                  {width >= 1000 ? (<LogoImage height={43} src={inl} />) : (<LogoImage height={32} src={inl} />)}
                </Link>

                <Buffer left={20}>
                  <Link href="https://breakthroughenergy.org/fellows-project/marathon-fusion/">
                    {width >= 1000 ? (<LogoImage height={56} src={bef} />) : (<LogoImage height={40} src={bef} />)}
                  </Link>
                </Buffer>

              </ImageFlexbox>
            </SplitSectionLogos>

          </Split>
          <Spacer $height={styles.S2} />
        </Body>
      </BackgroundBlock>

      <Body>
        {width >= 500 ? (<Spacer $height={styles.S1} />) : (<Spacer $height={styles.S3} />)}
        <ImageBox>
          <ImageBoxTextBox>
            <ImageBoxText>
              Fusion is the key to delivering energy, sustainability, and abundance across the world.
            </ImageBoxText>
          </ImageBoxTextBox>
          <BigImage src={landscape} />
        </ImageBox>
        {width >= 500 ? (<Spacer $height={styles.S1} />) : (<Spacer $height={styles.S3} />)}
      </Body>
    </>
  );

  return (
    <Background>
      <Body>
        <Header />
      </Body>
      {content}
      <Body>
        <Footer />
      </Body>
    </Background>
  );
}

export default App;
