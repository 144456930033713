import styled from "styled-components";

import Body from "../Components/Body";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import { Title, Paragraph, Link } from "../Components/Text";

import photo1 from "../images/photo1.png";

const FullImage = styled.img`
  width: 100%;
`;

function Technology() {
  const content = (
    <>
      <Title>The Fusion Fuel Cycle</Title>
      <Paragraph>
        The natural scarcity of tritium means that fusion power plants will
        require a closed fuel cycle, breeding and rapidly recycling their own
        tritium inventory to achieve self-sufficiency. And, to achieve an
        impact-relevant scale, these plants will also need to produce excess
        tritium to fuel future power plants.
      </Paragraph>
      <Paragraph>
        Existing tritium processing systems were designed decades ago for use in
        scientific-scale systems, using inherently non-continuous processes, and
        designed for a fundamentally different set of constraints than upcoming
        power plants.
      </Paragraph>
      <Paragraph>
        To achieve commercial relevance, the industry will have to undergo a
        step change in our tritium processing capabilities with regards to
        speed, robustness, and scalability.
      </Paragraph>
      <FullImage src={photo1} />

      <Title>The Superpermeable Hydrogen Pump</Title>
      <Paragraph>
        Marathon Fusion is taking the critical next step to translate the
        foundational understanding developed over decades of research into the
        phenomenon of “superpermeation” into a commercially-ready fuel
        processing component.
        <Paragraph></Paragraph>
        Unlike conventional technologies, throughput isn’t limited by the
        processing times or pressure requirements of diffusive permeation
        techniques, nor the cost and complexity inherent to cryogenic
        distillation. Our solution is simple, solid state, and highly scalable
        to meet the challenges of commercial fusion.
      </Paragraph>
      <Paragraph>
        However, work is still needed to get this technology up to sufficient
        maturity, and to prove out the practical engineering considerations
        required for commercial operation. Our aim over the next year is to
        collaborate with researchers in industry, academia and national labs to
        retire all key technical risks as described by Masashi Shimada at Idaho
        National Lab (
        <Link href="https://www.burningplasma.org/activities/uploads_tec/20170720%20FESAC%20TEC%20(M.Shimada,%20INL)%20v.4.pdf">
          presentation
        </Link>
        ,{" "}
        <Link href="https://www.burningplasma.org/activities/uploads_tec/FESAC_TEC_White_Paper%20(M.Shimada,%20INL)%20Rev.3.pdf">
          whitepaper
        </Link>
        ).
      </Paragraph>
    </>
  );

  return (
    <Body>
      <Header />
      {content}
      <Footer />
    </Body>
  );
}

export default Technology;
