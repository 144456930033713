import Body from "../Components/Body";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import { Title, Subtitle, Paragraph, Link } from "../Components/Text";

function Scientist() {
  const content = (
    <>
      <Title>Research Scientist</Title>
      <Subtitle>San Francisco, CA / Full Time</Subtitle>
      <Paragraph>
        Marathon Fusion is building the fuel cycle for fusion energy. We're
        accelerating the commercialization of critical technologies to ensure
        the safe and economic deployment of fusion energy at sufficient speed
        and scale to achieve climate impact.
      </Paragraph>
      <Paragraph>
        Our work requires a willingness to adapt to rapidly changing
        circumstances and shifting priorities, while keeping an eye on the
        long-term goals that ultimately drive success. Navigating this
        environment necessitates effective communication across roles and
        backgrounds, a high degree of trust between team members, and the
        willingness to learn and develop new skills on the job. We place
        particular weight on a team member's ability to be self-directed and
        proactive.
      </Paragraph>
      <Paragraph>
        In your first year at Marathon, you'll:
        <ul>
          <li>
            Get up to speed on state-of-the-art tritium processing systems
          </li>
          <li>
            Perform design work on cold plasma sources integrated into key fusion supporting components, and test these devices in the lab
          </li>
          <li>
            Craft technical roadmaps alongside our internal and external collaborators
          </li>
          <li>
            Push forward novel approaches to fuel processing relevant to near-term fusion power plants
          </li>
        </ul>
      </Paragraph>
      <Paragraph>
        The ideal candidate will meet most of these requirements:
        <ul>
          <li>
            A graduate degree in plasma physics
          </li>
          <li>Proficiency at data analysis and presentation.</li>
          <li>Excellent written and oral communication skills.</li>
          <li>Willingess to travel for conferences and client site visits.</li>
          <li>
            Eager to learn about the tritium fuel cycle and related technologies
          </li>
          <li>
            Excited to think broadly and at a systems-level about the requirements on the fuel cycle driven by confinement physics and vice-versa
          </li>
          <li>Basic operational grant management skills.</li>
        </ul>
      </Paragraph>
      <Paragraph>
        Candidates are not expected to have existing expertise in tritium
        processing.
      </Paragraph>
      <Paragraph>
        Marathon Fusion considers all qualified applicants equally for
        employment. We do not discriminate on the basis of race, color, national
        origin, ancestry, citizenship status, protected veteran status,
        religion, physical or mental disability, marital status, sex, sexual
        orientation, gender identity or expression, age, or any other basis
        protected by law, ordinance, or regulation.
      </Paragraph>
      <Paragraph>
        To apply, send your resume to{" "}
        <Link href="mailto:careers@marathonfusion.com">
          careers@marathonfusion.com
        </Link>
      </Paragraph>
    </>
  );

  return (
    <Body>
      <Header />
      {content}
      <Footer />
    </Body>
  );
}

export default Scientist;
